<template>
  <StaticPage>
    <el-card class="form_wrapper">
      <h2>{{ $t('forgetPwReq.headerMobile') }}</h2>
      <div class="from_box">
        <el-form :model="mobileForm" :rules="ruleMobile" ref="mobileForm">
          <el-row type="flex" justify="space-between">
            <el-col :span="10">
              <Autocomplete
                v-model="mobileForm.countrycode"
                name="countrycode"
                :codes="codes"
                :label="$t('common.field.countryCode')"
                @select="handleSelect"
                data-testid="countrycode"
              >
              </Autocomplete>
            </el-col>
            <el-col :span="13">
              <InputTemplate
                v-model.trim="mobileForm.mobile"
                name="mobile"
                :label="$t('common.field.phone')"
                data-testid="phone"
              >
              </InputTemplate>
            </el-col>
          </el-row>
          <el-button class="btn_sms" @click="sendSMSCode()" :disabled="!show">
            {{ show ? $t('forgetPwReq.getCode') : $t('forgetPwReq.sentAlready') + '(' + count + ')' }}
          </el-button>
        </el-form>
        <el-form :model="mobileForm" :rules="ruleSMS" ref="smsForm">
          <InputTemplate
            v-model.trim="mobileForm.smsCode"
            name="smsCode"
            :label="$t('forgetPwReq.loginSMSCode')"
            :placeholder="$t('forgetPwReq.loginSmsCodeExpires')"
            data-testid="smsCode"
          >
          </InputTemplate>
          <el-button @click="submitForm()" data-testid="submit">
            {{ $t('common.button.submit') }}
          </el-button>
        </el-form>
      </div>
    </el-card>
  </StaticPage>
</template>

<script>
import Autocomplete from '@/components/form/Autocomplete';
import StaticPage from '@/components/template/staticPage/StaticPageDefault';
import { apiReq_reset_get_sms, apiReq_reset_profile_password_mobile } from '@/resource';
import countryCodeEn from '@/assets/data/countrycode_en.json';
import countryCodeCn from '@/assets/data/countrycode_cn.json';
import helper from '@/util/signinHelper';
import { validateNumber } from '@/util/validation';
import { rsa } from '@/util/encrypt';

export default {
  name: 'ForgetPasswordReqMobile',
  components: { StaticPage, Autocomplete },
  data() {
    return {
      show: true,
      count: '',
      timer: null,
      countryCodeEnJson: countryCodeEn,
      countryCodeCnJson: countryCodeCn,
      codes: [],
      mobileForm: {
        mobile: '',
        countrycode: '',
        smsCode: ''
      },
      ruleMobile: {
        countrycode: [
          {
            required: true,
            trigger: 'blur',
            validator: validateNumber
          }
        ],
        mobile: [
          {
            required: true,
            trigger: 'blur',
            validator: validateNumber
          }
        ]
      },
      ruleSMS: {
        smsCode: [{ required: true, message: this.$t('forgetPwReq.formValidation.smsCodeReq'), trigger: 'blur' }]
      }
    };
  },
  methods: {
    countDown() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    sendSMSCode() {
      this.$refs.mobileForm.validate(valid => {
        if (valid) this.getApiReq_reset_get_sms();
        else return false;
      });
    },
    handleSelect(item) {
      this.mobileForm.countrycode = item.code;
    },
    submitForm() {
      this.$refs.smsForm.validate(valid => {
        if (valid) this.getApiReq_reset_profile_password_mobile();
        else return false;
      });
    },
    getApiReq_reset_get_sms() {
      apiReq_reset_get_sms({
        countryPhoneCode: this.mobileForm.countrycode,
        mobileNumber: rsa(this.mobileForm.mobile)
      }).then(resp => {
        console.log('resp: ', resp);
        if (resp.data.code === 0) this.countDown();
      });
    },
    getApiReq_reset_profile_password_mobile() {
      apiReq_reset_profile_password_mobile({
        countryPhoneCode: this.mobileForm.countrycode,
        mobileNumber: rsa(this.mobileForm.mobile),
        code: this.mobileForm.smsCode
      }).then(resp => {
        console.log('resp: ', resp);
        if (resp.data.code === 0) {
          helper.smsCodeLogin(resp.data.data);
        }
      });
    }
  },
  mounted() {
    if (this.lang === 'zh_CN') {
      this.codes = this.countryCodeCnJson;
    } else {
      this.codes = this.countryCodeEnJson;
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
@import '@/assets/css/pages/forgetPassword/forgetPasswordReq.scss';
</style>
